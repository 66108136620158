import React from "react"
import "swiper/css/swiper.css"

import { StaticImage } from "gatsby-plugin-image"
import { LazyLoadComponent } from "react-lazy-load-image-component"

class PartnerSwiper extends React.Component {
  render() {
    return (
      <section id="logos-wrapper" className="logos-wrapper">
        <div className="container">
          <div className="row p-3">
            <div className="col-10 col-lg-12 mx-auto text-center align-item-center mb-3">
              <div className="row">
                <div className="col-6 col-lg-3 col-md-3 mx-auto text-center align-item-center mb-3">
                  <StaticImage
                    className="logo-static-img"
                    imgClassName="img-fluid w-100 h-100"
                    src="https://cdn.sanity.io/images/55lcecww/production/dc8752ed97e43dc7efc6a42c16a0ff739bbbe031-1024x463.png"
                    alt="Walgreens logo"
                    layout="constrained"
                    // width={162}
                    loading="lazy"
                  />
                </div>
                <div className="col-6 col-lg-3 col-md-3 mx-auto text-center align-item-center mb-3">
                  <StaticImage
                    className="logo-static-img"
                    imgClassName="img-fluid"
                    src="https://cdn.sanity.io/images/55lcecww/production/e6a6dd072008cdd1ad1a048540217a1815681907-1024x463.jpg?w=300"
                    alt="Delta logo"
                    layout="constrained"
                    // width={162}
                    loading="lazy"
                  />
                </div>
                <div className="col-6 col-lg-3 col-md-3 mx-auto text-center align-item-center mb-3">
                  <StaticImage
                    className="logo-static-img"
                    imgClassName="img-fluid"
                    src="https://cdn.sanity.io/images/55lcecww/production/b1f4a5166764b2cda3b7b8ddb8744a4567b4dc34-1024x463.png?w=300"
                    alt="Jacobs logo"
                    // width={162}
                    // height={130}
                    layout="constrained"
                    loading="lazy"
                  />
                </div>
                <div className="col-6 col-lg-2 col-md-3 mx-auto text-center align-item-center mb-3">
                  <StaticImage
                    className="logo-static-img"
                    imgClassName="img-fluid"
                    src="https://cdn.sanity.io/images/55lcecww/production/8f3ab2079d549326561b2ce56d6aea08a4ef3562-1024x463.png?w=300"
                    alt="AINGroup logo"
                    layout="constrained"
                    // width={162}
                    loading="lazy"
                  />
                </div>
              </div>
            </div>

            <div className="col-10 col-lg-12 mx-auto text-center align-item-center mb-3">
              <div className="row">
                <div className="col-6 col-lg-2 col-md-3 mx-auto text-center align-item-center mb-3">
                  <StaticImage
                    className="logo-static-img"
                    imgClassName="img-fluid"
                    src="https://cdn.sanity.io/images/55lcecww/production/448496b812deae107dfcf177d708ac85fb3e51c2-1024x463.png?w=300"
                    alt="Amneal logo"
                    layout="constrained"
                    // width={162}
                    loading="lazy"
                  />
                </div>
                <div className="col-6 col-lg-2 col-md-3 mx-auto text-center align-item-center mb-3">
                  <StaticImage
                    className="logo-static-img"
                    imgClassName="img-fluid"
                    src="https://cdn.sanity.io/images/55lcecww/production/fa3d7da791600b858ec7cd0a5990fdef17cdaa9a-1024x463.png?w=300"
                    alt="Dexko logo"
                    // width={162}
                    layout="constrained"
                    loading="lazy"
                  />
                </div>
                <div className="col-6 col-lg-2 col-md-3 mx-auto text-center align-item-center mb-3">
                  <StaticImage
                    className="logo-static-img"
                    imgClassName="img-fluid"
                    src="https://cdn.sanity.io/images/55lcecww/production/1547f532d63b7a09ac7a0848a2197b8c341dbdf3-300x136.png"
                    alt="BV logo"
                    // width={162}
                    layout="constrained"
                    loading="lazy"
                  />
                </div>
                <div className="col-6 col-lg-2 col-md-3 mx-auto text-center align-item-center mb-3">
                  <StaticImage
                    className="logo-static-img"
                    imgClassName="img-fluid"
                    src="https://cdn.sanity.io/images/55lcecww/production/ca6e9737c0c7d63b41e2841ba22140cfa1487819-300x136.png"
                    alt="GT logo"
                    layout="constrained"
                    // width={162}
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default PartnerSwiper
